// src/googleAnalytics.js
import ReactGA from 'react-ga4';

const initGA = () => {
  // Initialize Google Analytics with your tracking ID
  ReactGA.initialize('G-01XQXB17XQ'); // Replace with your actual GA4 tracking ID
};

const logPageView = (path) => {
    // Log the page view with the given path
    ReactGA.send({ hitType: 'Nirogi Dhara pageview', page: path });
  };

export { initGA, logPageView };
