import React, { useEffect } from 'react';

const TermsAndConditions = () => {
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    return (
        <div>
            <div className="relative bg-cover bg-center bg-no-repeat h-[300px] flex items-center text-left bg-[url('https://shuddhi.com/wp-content/themes/twenty-twenty-one-child/assets/images/about-bg.jpg')] transition-opacity duration-1000 transform "><div className=" pl-36 z-10 rounded-lg opacity-100"><h1 className="text-3xl md:text-4xl font-bold tracking-wide text-green-800 mb-4">PRIVACY POLICY</h1>
            </div>
            </div>
            <div className="min-h-screen ">
                <div className="max-w-7xl mx-auto bg-white p-8 ">
                    {/* Main Content */}
                    <section className="mb-8">

                        <h2 className="text-xl font-semibold text-gray-600 mt-6 mb-3">PRIVACY & SECURITY</h2>
                        <p className="text-gray-500 mb-4">
                            Shuddhi adheres to the highest standards for secure transactions and protection of your Personal Information and its privacy. Please read the following statement to learn about our information gathering and dissemination practices.

                            Note: Our privacy policy is subject to change at any time without notice. To ensure that you are aware of any changes, please review this policy periodically. Shuddhi is committed to protecting and safeguarding your privacy.

                            This Privacy Policy describes the types of personal information that is collected on the Shuddhi website, how we mutually benefit from such information, how we may use that information and with whom we may share the same. The Privacy Policy also describes the measures we take to protect the security of this information as well as how you can access, modify or delete your personal information at any time. It also explains how you can object to the processing of your personal information or to receiving communications about our products and services.

                            By mere use of the website, you expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy. If you do not agree please do not use or access our Site. This Privacy Policy is incorporated into and subject to the
                        </p>
                    </section>

                    <h3 className="text-xl font-semibold text-gray-600 mb-2">Terms and Conditions</h3>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Collection Of Personally Identifiable Information And Other Information</h3>
                        <p className="text-gray-500 mb-4">Shuddhi limits itself to collect information in order to ensure accurate service. Most of the information we collect is very basic and is needed to complete a purchase or provide a refund. Examples of customer information that may be collected by us include your name, address, telephone number, date of birth, email address, descriptions of the items requested or purchased, language preference, the IP address and operating system of your computer, and the browser type and version being used by you.

                            We will not sell, distribute or lease your personal information to third parties. We may automatically track certain information about you based upon your behavior on our website. We use this information to do internal research on our users’ demographics, interests, and behavior to better understand, protect and serve our users. This information is compiled and analyzed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on our site or not), which URL you next go to (whether this URL is on our site or not), your computer browser information, and your IP address. Credit Card, Debit Card and Banking Information

                            Shuddhi does not collect or store Credit Card or Debit Card or any of your banking information as it is directly transmitted through the payment gateway provider to the payment network or bank.</p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Registration</h3>
                        <p className="text-gray-500 mb-4">
                            In order to take advantage of some features of the site, you must register and create a member account which is free of any cost. An online registration form may require you to provide information such as your name, email address, and a password.

                            Your password is a component of our security system. As such, it is your responsibility to protect it. Do not share your password with any third parties. If your password has been compromised for any reason, you should change it immediately.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2"></h3>
                        <p className="text-gray-500 mb-4">
                            Due to the prevailing COVID-19 lockdown and restrictions on movement, we regret to inform you that there is a certain delay in the delivery of products. Sorry for the inconvenience.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Technologies That Allow Us To Customise Your Experience On Shuddhi</h3>

                        <p className="text-gray-500 mb-4">
                            We use various technologies to collect information relating to your visit to our website, such as the Uniform Resource Locater (URL) that referred you to our website, Internet Protocol address, browser type, browser language, the date and time of your request, etc. This enables us to enhance and customise your experience on our website. For example, we may collect the Internet Protocol (IP) address identifying your computer or device and indicating your geographic region for statistical purposes. In some instances, we may use these technologies in combination with the personally identifiable information you provide on the website. These technologies may provide a variety of information such as whether you have visited Shuddhi before. They also may enable you to save your preferences. Each technology is explained below.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Web Beacons And Tracking Links</h3>
                        <p className="text-gray-500 mb-4">
                            Web Beacons (also called clear gifs and pixel tags), tracking links and/or similar technology consist of a few lines of code and are embedded on the pages of our website. They are often used in combination with Cookies and are often not visible to the user of the website. Web Beacons may relay information to third parties such as our service providers and may be used in order to track customer response to certain advertising, to better target interactive advertising and to enhance customer support and usability. If you reject our Cookies (see Cookie section below), you will prevent Web Beacons from relaying this information about you and may limit your use of some of our website features.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Cookies</h3>
                        <p className="text-gray-500 mb-4">
                            We use data collection devices such as “cookies” on certain pages of the website to help analyze our web page flow, measure promotional effectiveness, and promote trust and safety. “Cookies” are small files placed on your hard drive that assist us in providing our services. We offer certain features that are only available through the use of a “cookie”. We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are “session cookies,” meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the website and you may be required to reenter your password more frequently during a session.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Advertisements On Shuddhi</h3>
                        <p className="text-gray-500 mb-4">
                            We use third-party advertising companies to serve ads when you visit our website. These companies may use information (not including your name, address, email address, or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you. We do not take any responsibility with respect to your visits to such other websites and providing any information/s therein.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Policy Change</h3>
                        <p className="text-gray-500 mb-4">
                            Our Privacy Policy may change from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice (including, for certain services, email notification of privacy policy changes). We will also keep prior versions of this Privacy Policy in an archive for your review.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Your Comments</h3>
                        <p className="text-gray-500 mb-4">
                            We welcome your feedback. If you have questions or comments about our privacy policies, feel free to drop us a message by writing to us at care@Shuddhi .com
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">OUR RIGHTS</h3>
                        <p className="text-gray-500 mb-4">
                            We may elect to electronically monitor areas of the portal and may disclose any Content, records, or electronic communication of any kind (i) to satisfy any law, regulation, or government request; (ii) if such disclosure is necessary or appropriate to operate the portal; or (iii) to protect our rights or property or the rights of the users, Sponsors, Providers, Licensors, or Merchants.

                            We are not responsible for screening, policing, editing, or monitoring such Content. If notified of allegedly infringing, defamatory, damaging, illegal, or offensive Content, we may investigate the allegation and determine in our sole discretion whether to remove or request the removal of such Content from the portal. We may terminate your access, or suspend your access to all or part of the Site, without notice, for any conduct that we, in our sole discretion, believe is in violation of any applicable law or is harmful to the interests of another user, a third-party Provider, Merchant, Sponsor, Licensor, service provider, or us. Because customer service is paramount to our business, we reserve the right to refuse to sell products to you if it reasonably appears to us that you intend to resell the products. In addition, we reserve the right to limit quantities of items purchased by each customer.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Refund Policy</h3>
                        <p className="text-gray-500 mb-4">
                            1. Eligibility for Refunds:
                            Refunds are only applicable for products that are damaged, defective, or incorrect upon delivery.
                            Customers must notify us within 7 days of receiving the product to be eligible for a refund.
                            Products must be unused, unopened, and in their original packaging to qualify for a refund.

                            2. Non-Refundable Items:
                            Ayurvedic supplements and consumable products (e.g., herbal powders, oils) cannot be refunded due to health and safety reasons, unless damaged or defective.
                            Personalized or customized products are not eligible for refunds.

                            3. Refund Process:
                            Once your return is received and inspected, we will notify you of the approval or rejection of your refund.
                            Approved refunds will be processed within 7-10 business days, and a credit will automatically be applied to your original method of payment.
                            Shipping costs are non-refundable.

                            4. Late or Missing Refunds:
                            If you haven’t received a refund yet, first check your bank account or contact your credit card company. It may take some time before your refund is officially posted.
                            If you’ve done this and still haven’t received your refund, please contact us at care@jeenasikho.com
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Cancellation Policy</h3>
                        <p className="text-gray-500 mb-4">
                            1. Order Cancellation:
                            Orders can be canceled within 24 hours of purchase for a full refund, provided the product has not been shipped.
                            Once an order has been shipped, it cannot be canceled.

                            2. How to Cancel:
                            To cancel your order, please contact us immediately at care@jeenasikho.com or 7398673986. Include your order number and the reason for cancellation.

                            3. Auto-Cancellation:
                            In case of stock unavailability or issues with shipping, we reserve the right to cancel your order. You will be notified, and a full refund will be processed.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Return Policy</h3>
                        <p className="text-gray-500 mb-4">
                            1. Return Eligibility:
                            To be eligible for a return, the item must be unused, in the same condition as received, and in the original packaging.
                            Returns must be initiated within 7 days of receiving the product.
                            To start a return, contact us at care@jeenasikho.com, and we will provide instructions on how and where to send your package.

                            2. Return Shipping:
                            Customers are responsible for return shipping costs unless the product is damaged or defective.

                            3. Processing Time:
                            Returns are processed within 7 business days of receiving the product at our facility. Once processed, we will issue a refund or store credit, as per your preference.

                        </p>
                    </section>

                    <section className="mb-8">
                        <p className=" text-gray-500 mb-2"><b className='text-xl font-semibold text-gray-600 '>Exchange Policy</b>
                            Product Exchanges:
                            1. Exchanges are only offered for defective or damaged products. If you need to exchange a product, email us at care@jeenasikho.com
                            Exchanges must be requested within 7 days of receiving the product.

                            2. Process:
                            Once your exchange request is approved, ship the item back to us in its original condition, and we will send a replacement product once the return is processed.
                        </p>
                    </section>

                    <section className=''>
                        <p className='text-gray-500 mb-1 '>Worldwide Copyright © Shuddhi Pvt. Ltd. (brand owners Shuddhi). All worldwide rights reserved.</p>
                    </section>


                    {/* Other sections continue... */}


                </div>
            </div>

        </div>
    );
};

export default TermsAndConditions;
