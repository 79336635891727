// src/components/MetaPixel.js
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const MetaPixel = () => {
  const pixelId = '3480985782207768'; // Replace with your actual Pixel ID

  useEffect(() => {
    // Only load the Pixel script if it's not already loaded
    if (!window.fbq) {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/fbevents.js';
      script.async = true;
      script.onload = () => {
        window.fbq('init', pixelId); // Initialize the Pixel
        window.fbq('track', 'PageView'); // Track page view immediately after loading
      };
      document.head.appendChild(script);
    } else {
      // If fbq is already available, track the page view
      window.fbq('track', 'PageView');
    }
  }, [pixelId]); // Run this effect only when the pixelId changes (in case you want to change it)

  return (
    <Helmet>
      <script>
        {`
          !function(f,b,e,v,n,t,s) {
            if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
            n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);
            t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${pixelId}'); // Initialize with your Pixel ID
          fbq('track', 'PageView'); // Track page view by default
        `}
      </script>
      <noscript>
        {`<img height="1" width="1" style="display:none" 
          src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1" />`}
      </noscript>
    </Helmet>
  );
};

export default MetaPixel;
