import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InquiryForm from "./ProductInquery";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { SlSocialInstagram } from "react-icons/sl";
import { FiYoutube } from "react-icons/fi";
import config from '../../config';

const SingleProduct = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState({});
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [filteredRelatedProducts, setFilteredRelatedProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const { productId } = useParams();
    const [activeTab, setActiveTab] = useState('description');
    const [selectedImage, setSelectedImage] = useState(null); // For image switching
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [inputValue, setInputValue] = useState(1); // Initial value of 1
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    // Toggle Offcanvas function
    const toggleOffcanvas = () => {
        setShowOffcanvas(!showOffcanvas);
    };
    const handleAddtoCart = async (e) => {
        e.preventDefault();
        
        // Retrieve cookie values safely
        const useremail = Cookies.get('username') || '';  // Add fallback to empty string
        const usermobile = Cookies.get('mobile') || '';   // Add fallback to empty string
        const qty = inputValue;                           // Quantity input value
    
        // Log to check values before sending
        console.log("Data to send Session:", { useremail, usermobile, productId, qty });
        window.location.reload(); 
        
        // If productId or qty are missing, alert user to check the product and quantity
        if (!productId || !qty) {
            alert('Please select a product and quantity.');
            return;
        }
    
        // Create a product object with all the product details
        const productData = {
            productId,
            qty: parseInt(qty, 10),  // Parse qty as integer
            useremail,               // Optional: Empty string for guest user
            usermobile,              // Optional: Empty string for guest user
            productName: products.productName,   // Add product name
            price: products.price,            // Add product price
            description: products.description,  // Add product description
            image: selectedImage,             // Add selected image
        };
    
        // Check if there's already a cart in sessionStorage
        let cart = JSON.parse(sessionStorage.getItem('cart')) || [];
        
        // Add the new product to the cart
        cart.push(productData);
        
        // Save the updated cart back to sessionStorage
        sessionStorage.setItem('cart', JSON.stringify(cart));
    
        alert('Added to Cart successfully');
    };     
    
    useEffect(() => {
        const fetchProduct = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${config.API_BASE_URL}/product/${productId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setProducts(data);
                setSelectedImage(data.images && JSON.parse(data.images)[0]); // Set the first image as the selected image
            } catch (error) {
                console.error('Error fetching product:', error);
            } finally {
                setLoading(false); // Stop loading
            }

        };
        fetchProduct();
    }, [productId]);
    useEffect(() => {
        const fetchRelatedProducts = async () => {
            try {
                const response = await fetch(`${config.API_BASE_URL}/fetchproducts`);
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                const data = await response.json();
                setRelatedProducts(data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        fetchRelatedProducts();
    }, []);
    useEffect(() => {
        if (products.category) {
            const filtered = relatedProducts.filter(
                (product) => product.category === products.category && products.id !== product.id
            );
            setFilteredRelatedProducts(filtered);
        }
    }, [relatedProducts, products.category, productId]);
    const productview = (productId) => {
        // alert("Product View");
        navigate(`/singleproduct/${productId}`);
        window.location.reload();
    }
    // Scroll to top effect
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []); // Empty dependency array ensures it runs only once when the component mounts
    const renderTabContent = () => {
        switch (activeTab) {
            case 'description':
                return (
                    <div className="mt-6">
                        <h3 className="text-2xl font-bold mb-4">Description</h3>
                        <p className="text-gray-600 mb-4">
                            {products.description}
                        </p>
                    </div>
                );
            case 'additionalInfo':
                return (
                    <div className="mt-6">
                        <h3 className="text-2xl font-bold mb-4">Additional Information</h3>
                        <p className="text-gray-600">
                            {products.information}
                        </p>
                    </div>
                );
            case 'reviews':
                return (
                    <div className="mt-6">
                        <h3 className="text-2xl font-bold mb-4">Reviews (0)</h3>
                        <p className="text-gray-600">
                            There are no reviews yet for this product. Be the first to write one!
                        </p>
                    </div>
                );
            default:
                return null;
        }
    };
    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-green-600"></div>
                {/* You can also use a spinner component from a library like React Spinner or similar */}
            </div>
        );
    }
    return (
        <div className="bg-gray-50">
            {/* Product Section */}
            <div className="container mx-auto mt-8 p-4">
                <div className="md:grid grid-cols-1 md:grid-cols-10 gap-8 sm:flex flex-col">
                    {/* Image Section (reduce width by 30%) */}
                    <div className="col-span-3 bg-white p-4">
                        {/* Main Image */}
                        {selectedImage ? (
                            <img
                                src={`${config.API_BASE_URL}/images/${selectedImage}`}
                                alt="Main Product Image"
                                className="w-full h-50 object-cover rounded-lg mb-4"
                            />
                        ) : (
                            <p>No image available</p>
                        )}

                        {/* Thumbnails */}
                        <div className="flex space-x-4">
                            {products.images && JSON.parse(products.images).map((image, index) => (
                                <img
                                    key={index}
                                    src={`${config.API_BASE_URL}/images/${image}`}
                                    alt={`Product Image ${index + 1}`}
                                    className={`w-20 h-20 object-cover rounded-lg border border-gray-300 cursor-pointer ${selectedImage === image ? 'border-green-600' : ''}`}
                                    onClick={() => setSelectedImage(image)} // On click, set the clicked image as the main image
                                />
                            ))}
                        </div>
                    </div>

                    {/* Product Details Section (increase width by 30%) */}
                    <div className="col-span-7 bg-white p-2 flex justify-between">
                        <div className="">
                            <div className="">
                                <h2 className="text-3xl font-semibold mb-2">{products.productName}
                                
                                    <span className="flex space-x-4 mt-2">
                                        {products.fbLink && (
                                            <a href={products.fbLink} target="_blank" rel="noopener noreferrer">
                                                <FaFacebookF className="fa fa-facebook-square text-xl text-blue-600" />
                                            </a>
                                        )}
                                        {products.instaLink && (
                                            <a href={products.instaLink} target="_blank" rel="noopener noreferrer">
                                                <SlSocialInstagram className="fa fa-instagram text-xl text-pink-600" />
                                            </a>
                                        )}
                                        {products.youtubeLink && (
                                            <a href={products.youtubeLink} target="_blank" rel="noopener noreferrer">
                                                <FiYoutube className="fa fa-youtube-play text-xl text-red-600" />
                                            </a>
                                        )}
                                    </span>
                                </h2>
                            </div>
                            <div className="flex items-center mb-4">
                                <p className="text-red-600 text-4xl line-through mr-4">₹ {products.overprice}/-</p>
                                <p className="text-green-600 font-medium text-4xl ml-4">₹ {products.price}/-</p>
                            </div>
                            {/* Product Features */}
                            <ul className="mb-4 list-disc list-inside text-lg space-y-2">
                                {products.features && JSON.parse(products.features).map((feature, index) => (
                                    <li key={index}>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            <h5>Last Product Sell: {products.lastProductSell}</h5>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    value={inputValue}
                                    onChange={handleInputChange} // Handle input changes
                                    className="border border-gray-300 rounded-lg px-0.5 py-2 ml-4 mr-4"
                                />

                                <button onClick={handleAddtoCart} className="bg-yellow-600 transition text-white px-6 py-2 rounded-full hover:bg-yellow-700 duration-300">
                                    Add to Cart
                                </button>
                            </div>
                            <h5>Category: {products.category}</h5>
                        </div>
                        {/* Right-Aligned Sticky Inquiry Button */}
                        <div className="flex items-center ml-auto">
                            <button
                                className="bg-green-600 text-white px-6 py-2 rounded-full uppercase transform -rotate-90 animate-glow"
                                onClick={toggleOffcanvas}
                            >
                                Inquiry about this product
                            </button>
                            {/* Offcanvas */}
                            <div
                                className={`fixed top-0 right-0 w-80 h-full bg-white shadow-lg z-50 transform ${showOffcanvas ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}
                            >
                                <div className="p-6">
                                    <button
                                        className="text-red-500 font-bold border border-red-500 px-3 py-1 rounded-full hover:bg-red-500 hover:text-white transition duration-300 block ml-auto"
                                        onClick={toggleOffcanvas}
                                    >
                                        X
                                    </button>
                                    <InquiryForm products={products} />
                                </div>
                            </div>
                            {/* Glow Effect Keyframes */}
                            <style jsx>{`
            @keyframes glow {
                0% {
                    box-shadow: 0 0 5px #33ff33, 0 0 10px #33ff33, 0 0 20px #33ff33, 0 0 40px #33ff33;
                }
                50% {
                    box-shadow: 0 0 10px #66ff66, 0 0 20px #66ff66, 0 0 40px #66ff66, 0 0 80px #66ff66;
                }
                100% {
                    box-shadow: 0 0 5px #33ff33, 0 0 10px #33ff33, 0 0 20px #33ff33, 0 0 40px #33ff33;
                }
            }
            .animate-glow {
                animation: glow 2s infinite;
            }
        `}</style>
                        </div>
                    </div>
                </div>
                {/* Description Section */}
                <div className="mt-8 bg-white p-4 rounded-lg shadow">
                    {/* Tab Navigation */}
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8 sm:space-x-4">
                            <button
                                onClick={() => setActiveTab('description')}
                                className={`${activeTab === 'description'
                                    ? 'border-green-500 text-white bg-green-600 flex justify-center align-middle'
                                    : 'border-transparent text-gray-500'
                                    } whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm`}
                            >
                                Description
                            </button>
                            <button
                                onClick={() => setActiveTab('additionalInfo')}
                                className={`${activeTab === 'additionalInfo'
                                    ? 'border-green-500  text-white bg-green-600 '
                                    : 'border-transparent text-gray-500'
                                    } whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm`}
                            >
                                Additional Information
                            </button>
                            <button
                                onClick={() => setActiveTab('reviews')}
                                className={`${activeTab === 'reviews'
                                    ? 'border-green-500  text-white bg-green-600 '
                                    : 'border-transparent text-gray-500'
                                    } whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm`}
                            >
                                Reviews (0)
                            </button>
                        </nav>
                    </div>
                    {renderTabContent()}
                </div>
                {/* Related Products Section */}
                <div className="mt-8">
                    <h3 className="text-xl font-semibold mb-4">Related Products</h3>
                    {filteredRelatedProducts.length > 0 ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 text-center">
                            {filteredRelatedProducts.map((product, index) => (
                                <div key={product.id} className={`p-5 bg-white rounded-lg shadow transition-all duration-1000 ease-out 
                    hover:border border-gray-400`}>
                                    {product.images && JSON.parse(product.images).length > 0 ? (
                                        <img
                                            src={`${config.API_BASE_URL}/images/${JSON.parse(product.images)[0]}`}
                                            alt={`Product Image`}
                                            className="w-full h-50 object-cover rounded-lg mb-4"
                                        />
                                    ) : (
                                        <p>No image available</p>
                                    )}
                                    <h3 className="text-lg font-semibold text-gray-800">{product.productName}</h3>
                                    <div className="flex items-center mb-2 justify-center">
                                        <p className="text-red-600 text-3xl line-through mr-4">₹ {product.overprice}/-</p>
                                        <p className="text-green-600 text-3xl">₹ {product.price}/-</p>
                                    </div>
                                    <button
                                        onClick={() => productview(product.id)} // Add this function if you want to navigate
                                        className="mt-4 mx-4 bg-green-600 text-white py-2 px-4 rounded-full hover:bg-green-700 transition duration-300"
                                    >
                                        View Product
                                    </button>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-center mt-4">No related products. Coming soon!</p>
                    )}
                </div>
                {/* Newsletter Section */}
                <div className="mt-8 bg-white p-4 rounded-lg shadow flex justify-between items-center">
                    <div>
                        <h3 className="text-xl font-semibold">Newsletter</h3>
                        <p>Signup our newsletter to get update information, news, insight or promotions.</p>
                    </div>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        className="px-4 py-2 border rounded-lg mr-2"
                    />
                    <button className="bg-green-600 text-white px-6 py-2 rounded-full">Sign up</button>
                </div>
            </div>
            {/* Footer Section */}
            <footer className="bg-gray-200 p-4 mt-8">
                <div className="container mx-auto flex justify-between">
                    <p>&copy; 2022 bagasaka. All rights reserved.</p>
                    <nav>
                        <a href="#" className="mx-2 text-gray-600 hover:text-gray-900">About us</a>
                        <a href="#" className="mx-2 text-gray-600 hover:text-gray-900">Team</a>
                        <a href="#" className="mx-2 text-gray-600 hover:text-gray-900">Services</a>
                        <a href="#" className="mx-2 text-gray-600 hover:text-gray-900">Shop</a>
                        <a href="#" className="mx-2 text-gray-600 hover:text-gray-900">Contact us</a>
                    </nav>
                </div>
            </footer>
        </div>
    );
};

export default SingleProduct;
