import React, { useState, useEffect} from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FaRegCirclePlay } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ServiceSteps from "../ServicesSteps";
import NewsletterSection from "../Newsletter";
import TestimonialsSection from "../Testimonials";
import { Link } from "react-router-dom";


const Aboutus = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handlePlayVideo = () => {
    setIsVideoPlaying(true);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Show 5 logos by default
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  console.log(typeof "Hello World");

  return (
    <>
      <div className="bg-gray-50 p-10 flex flex-col md:flex-row items-center justify-between px-4 sm:px-10 md:px-20 mt-4">
        {/* Image/Video with Play Button Overlay */}
        <div className="relative w-full md:w-1/2 mb-6 md:mb-0 px-4 sm:px-8 md:px-16">
          {!isVideoPlaying ? (
            <div className="relative">
              <img
                src="https://fullkit.moxcreative.com/bagasaka/wp-content/uploads/sites/9/2022/07/kizhi-or-herbal-bolus-bags-ayurveda-massage.jpg"
                alt="Experience"
                className="w-full rounded-lg shadow-lg object-cover"
              />
              {/* Play Button Overlay */}
              <div
                className="absolute inset-0 flex items-center justify-center cursor-pointer"
                onClick={handlePlayVideo}
              >
                <div className="bg-green-600 text-white p-3 sm:p-4 md:p-5 rounded-full hover:bg-green-700 transition z-20">
                  <FaRegCirclePlay className="text-4xl sm:text-5xl md:text-6xl text-white" />
                </div>
              </div>
              {/* Experience Text */}
              <div className="absolute top-6 left-6 sm:top-8 sm:left-8 bg-green-600 text-white p-3 sm:p-4 rounded-lg shadow-lg z-10">
                <span className="text-lg sm:text-xl md:text-2xl font-bold">
                  25+
                </span>
                <p className="text-xs sm:text-sm mt-1">Years Experience</p>
              </div>
            </div>
          ) : (
            // Video Embed
            <iframe
              className="w-full h-48 sm:h-64 md:h-96 rounded-lg shadow-lg"
              src="https://www.youtube.com/embed/BrLZc-kLd1M?si=Tu271DJxVP3I4nNq"
              title="Experience Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </div>

        {/* Text Content */}
        <div className="md:w-1/2 text-center md:text-left p-4 sm:p-6 md:p-5">
          <h5 className="italic font-semibold text-green-600 text-sm sm:text-base">
            Who we are
          </h5>
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-700 mb-4">
            The combination of nature and science.
          </h2>
          <p className="text-sm sm:text-base text-gray-500 mb-6">
            By blending the wisdom of natural remedies with the precision of
            modern scientific research, we can create treatments that are both
            effective and gentle. It ensures long-lasting results while
            nurturing the body in a holistic way.
          </p>
          <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700"></hr>

          <ul className="text-sm sm:text-base text-gray-700 space-y-2">
            <li className="font-semibold text-green-600">
              <FaCheckCircle className="inline mr-2 text-green-600" />
              100% Natural Products
            </li>
            <li className="font-semibold text-green-600">
              <FaCheckCircle className="inline mr-2 text-green-600" />
              Organic Herbal
            </li>
            <li className="font-semibold text-green-600">
              <FaCheckCircle className="inline mr-2 text-green-600" />
              24/7 Support
            </li>
          </ul>
        </div>
      </div>

      {/* Featured Brand Section */}

      {/* Value Section */}
      <div className="relative bg-cover bg-center  p-52 bg-[url('https://www.navayurherbals.com/wp-content/uploads/2023/04/navayur-blog-768x486.webp')]">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative text-center text-white">
          <h2 className="text-4xl font-bold mb-4">
            Treat your body with the care of nature
          </h2>
          <p className="mb-8">
            Our remedies bring the extraordinary healing power of nature to
            everyday people, making wellness accessible and effective for all.
          </p>
          {/* <Link
                        to="/Aboutus"
                        className="bg-green-600 hover:bg-green-700 transition py-2 px-6 rounded-full"
                    >
                        Discover More
                    </Link> */}
        </div>
      </div>

      <ServiceSteps />
      <TestimonialsSection />
      <NewsletterSection />
    </>
  );
};

export default Aboutus;
