// src/components/DiseaseFormModal.js
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import config from '../../config';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent background
    zIndex: 9999, // High z-index to overlay on other content
  },
  content: {
    top: '35%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '500px',
    zIndex: 10000, 
  },
};

const DiseaseFormModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    disease: '',
  });

  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(null);
  useEffect(() => {
    if (isOpen) {
      // Reset form when modal opens
      setFormData({ name: '', email: '', phone: '', disease: '' });
      fetchCategories();
    }
  }, [isOpen]);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/fetchcategories`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setCategories([, ...data.map((cat) => cat.category_name)]);  // Assuming API returns an array of objects with a `category_name` property
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    
    console.log(formData)
  try {
      const response = await fetch(`${config.API_BASE_URL}/inquiry1`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
             // Send the entire products object
              ...formData
          })
      });

      if (!response.ok) {
          throw new Error('Failed to submit inquiry');
      }

      // Show success alert and reset form data
      setAlert({ type: 'success', message: 'Inquiry submitted successfully!' });
      setFormData({
          name: '',
          email: '',
          contact: '',
          inquiry: ''
      });
  } catch (error) {
      console.error('Error submitting inquiry:', error);

      // Show error alert
      setAlert({
          type: 'error',
          message: 'Due to some internal errors your inquiry is not submitted, please contact our customer support team.'
      });
  }
    // Handle form submission (e.g., save to local storage, send to API)
    console.log(formData);
    onClose(); // Close modal after submission
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Disease Form Modal"
    >
      <h2 className="text-xl font-bold mb-4 text-center">Connect with Us</h2>
      <button onClick={onClose} className="absolute top-2 right-2 text-xl font-bold hover:text-red-600">X</button>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500"
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500"
        />
        <input
          type="text"
          name="phone"
          placeholder="Your Phone"
          value={formData.phone}
          onChange={handleChange}
          required
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500"
        />
        <select
          name="disease"
          value={formData.disease}
          onChange={handleChange}
          required
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500"
        >
         <option value="">Select Disease</option>
          {categories.length > 0 ? (
            categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))
          ) : (
            <option value="">Loading...</option>
          )}
        </select>
        <button
          type="submit"
          className="w-full p-2 bg-green-700 text-white rounded hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-500"
        >
          Submit
        </button>
      </form>
    </Modal>
  );
};

export default DiseaseFormModal;
