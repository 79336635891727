import React, { useState, useRef } from 'react';
import gsap from 'gsap';
import './TruckButton.css';

const TruckButton = ({ onClick, defaultText, successText, failureText, paymentStatus }) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    const buttonRef = useRef(null);
    const boxRef = useRef(null);
    const truckRef = useRef(null);

    const handleClick = (e) => {
        e.preventDefault();

        if (!isComplete) {
            if (!isAnimating) {
                setIsAnimating(true);

                gsap.to(buttonRef.current, {
                    '--box-s': 1,
                    '--box-o': 1,
                    duration: 0.3,
                    delay: 0.5,
                });

                gsap.to(boxRef.current, {
                    x: 0,
                    duration: 0.4,
                    delay: 0.7,
                });

                gsap.to(buttonRef.current, {
                    '--hx': -5,
                    '--bx': 50,
                    duration: 0.18,
                    delay: 0.92,
                });

                gsap.to(boxRef.current, {
                    y: 0,
                    duration: 0.1,
                    delay: 1.15,
                });

                gsap.set(buttonRef.current, {
                    '--truck-y': 0,
                    '--truck-y-n': -26,
                });

                gsap.to(buttonRef.current, {
                    '--truck-y': 1,
                    '--truck-y-n': -25,
                    duration: 0.2,
                    delay: 1.25,
                    onComplete() {
                        gsap
                            .timeline({
                                onComplete() {
                                    setIsComplete(true);
                                },
                            })
                            .to(truckRef.current, {
                                x: 0,
                                duration: 0.4,
                            })
                            .to(truckRef.current, {
                                x: 40,
                                duration: 1,
                            })
                            .to(truckRef.current, {
                                x: 20,
                                duration: 0.6,
                            })
                            .to(truckRef.current, {
                                x: 96,
                                duration: 0.4,
                            });

                        gsap.to(buttonRef.current, {
                            '--progress': 1,
                            duration: 2.4,
                            ease: 'power2.in',
                        });
                    },
                });
            }
        } else {
            setIsAnimating(false);
            setIsComplete(false);

            gsap.set(truckRef.current, {
                x: 4,
            });
            gsap.set(buttonRef.current, {
                '--progress': 0,
                '--hx': 0,
                '--bx': 0,
                '--box-s': 0.5,
                '--box-o': 0,
                '--truck-y': 0,
                '--truck-y-n': -26,
            });
            gsap.set(boxRef.current, {
                x: -24,
                y: -6,
            });
        }

        onClick(); // Notify parent of button click
    };

    const getButtonText = () => {
        if (paymentStatus === true) return successText;
        if (paymentStatus === false) return failureText;
        return defaultText;
    };

    return (
        <button
            ref={buttonRef}
            className={`truck-button ${isAnimating ? 'animation' : ''} ${isComplete ? 'done' : ''}`}
            onClick={handleClick}
        >
            <span className="default">{getButtonText()}</span>
            <div ref={truckRef} className="truck">
                <div className="wheel"></div>
                <div className="back"></div>
                <div className="front"></div>
                <div ref={boxRef} className="box"></div>
            </div>
        </button>
    );
};

export default TruckButton;
