import React, { useState, useEffect } from "react";
import config from "./../../config";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [faq, setFaq] = useState([]); // Rename this to match the variable you're using

  useEffect(() => {
    const fetchFAQ = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/fetchfaq`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setFaq(data); // Assuming `data` is an array of FAQ objects
      } catch (error) {
        console.error("Error fetching FAQ:", error);
      }
    };

    fetchFAQ();
  }, []);

  const toggleFAQ = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <div className="relative bg-cover bg-center bg-no-repeat h-[300px] flex items-center text-left bg-[url('https://shuddhi.com/wp-content/themes/twenty-twenty-one-child/assets/images/about-bg.jpg')] transition-opacity duration-1000 transform ">
        <div className="pl-36 z-10 rounded-lg opacity-100">
          <h1 className="text-3xl md:text-4xl font-bold tracking-wide text-green-800 mb-4">
            Frequently Asked Questions
          </h1>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="max-w-4xl mx-auto py-10">
          <header className="text-center mb-8"></header>
          <div className="bg-white shadow-md rounded-lg">
            {faq.map((faqItem, index) => (
              <div key={index} className="border-b last:border-b-0">
                <button
                  className="w-full flex justify-between items-center text-left px-6 py-4 text-lg font-medium text-black bg-gray-100 hover:bg-gray-200 transition"
                  onClick={() => toggleFAQ(index)}
                >
                  <span>{faqItem.faqQuestion}</span>
                  <svg
                    className={`w-6 h-6 transform transition-transform ${openIndex === index ? "rotate-180" : ""}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                <div
                  className={`px-6 overflow-hidden transition-all duration-300 ${openIndex === index ? "max-h-screen py-4" : "max-h-0"}`}
                >
                  <p className="text-green-700">{faqItem.faqAnswer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
