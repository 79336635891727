import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

export default function VideoSection() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVisibleLeft, setIsVisibleLeft] = useState(false);
  const [isVisibleRight, setIsVisibleRight] = useState(false);
  const [isVisibleBackground, setIsVisibleBackground] = useState(false); // New state for background section
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const backgroundRef = useRef(null); // Ref for background section

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1, // Trigger when 10% of the element is visible
    };

    const leftObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsVisibleLeft(true);
      }
    }, observerOptions);

    const rightObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsVisibleRight(true);
      }
    }, observerOptions);

    const backgroundObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsVisibleBackground(true); // Set visibility when background section is in view
      }
    }, observerOptions);

    if (leftRef.current) {
      leftObserver.observe(leftRef.current);
    }

    if (rightRef.current) {
      rightObserver.observe(rightRef.current);
    }

    if (backgroundRef.current) {
      backgroundObserver.observe(backgroundRef.current);
    }

    return () => {
      if (leftRef.current) leftObserver.unobserve(leftRef.current);
      if (rightRef.current) rightObserver.unobserve(rightRef.current);
      if (backgroundRef.current) backgroundObserver.unobserve(backgroundRef.current);
    };
  }, []);

  const handlePlayClick = () => {
    setIsPlaying(true);
  };
   
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <div>
      <div className="flex justify-around items-center py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-16">
          <div
            ref={leftRef}
            className={`relative transition-opacity duration-1000 transform ${isVisibleLeft ? 'opacity-100 animate-fadeInLeft' : 'opacity-0'
              }`}
          >
            {!isPlaying ? (
              <div className="cursor-pointer" onClick={handlePlayClick}>
                <img
                  src="https://as2.ftcdn.net/v2/jpg/03/93/85/55/1000_F_393855516_0BcgVZqNlVrvDE0kiD3YTlVJaur8Q02G.jpg"
                  alt="Video Thumbnail"
                  className="rounded-lg bg-position-center bg-cover bg-no-repeat" />
                <div className="absolute inset-0 flex justify-center items-center">
                  <div className="bg-white bg-opacity-75 p-3 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-12 w-12"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.752 11.168l-4.596-2.65A1 1 0 009 9.324v5.352a1 1 0 001.156.986l4.596-2.65a1 1 0 000-1.744z" />
                    </svg>
                  </div>
                </div>
              </div>
            ) : (
              <iframe
                className="rounded-lg"
                width="100%"
                height="350"
                src="https://www.youtube.com/embed/BrLZc-kLd1M?si=Tu271DJxVP3I4nNq"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>

          <div
            ref={rightRef}
            className={`transition-opacity duration-1000 transform ${isVisibleRight ? 'opacity-100 animate-fadeInRight' : 'opacity-0'
              }`}
          >
            <h4 className="italic font-semibold text-green-700">
              Who we are
            </h4>
            <h2 className="text-4xl font-bold text-gray-800">
              The combination of nature and science.
            </h2>
            <p className="mt-4 text-gray-600">
              By blending the wisdom of natural remedies with the precision of modern scientific research, we can create treatments that are both effective and gentle. It ensures long-lasting results while nurturing the body in a holistic way.
            </p>
            <ul className="mt-4 space-y-2">
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-green-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
                <span className="ml-2 text-gray-700">100% Natural Products</span>
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-green-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
                <span className="ml-2 text-gray-700">Organic Herbal</span>
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-green-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
                <span className="ml-2 text-gray-700">24/7 Support</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Section with background image and fade-in effect */}
      <div
        ref={backgroundRef}
        className={`relative bg-cover bg-center bg-no-repeat h-[500px] flex items-center justify-center bg-[url('https://www.navayurherbals.com/wp-content/uploads/2023/04/navayur-blog-768x486.webp')] transition-opacity duration-1000 transform `}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className={`text-center p-6 z-10 rounded-lg ${isVisibleBackground ? 'opacity-100 animate-fadeIn' : 'opacity-0'
          }`}>
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
            Extraordinary medicine for ordinary people.
          </h1>
          <p className="text-white mb-6 animate-fadeIn delay-500">
            Our remedies bring the extraordinary healing power of nature to everyday people, making wellness accessible and effective for all.
          </p>
          <Link
            to="/Aboutus"
            className="inline-block px-6 py-3 text-lg font-medium bg-green-500 text-white rounded-full transition-transform duration-500 ease-in-out hover:bg-green-600 hover:scale-105 animate-fadeIn delay-400"
          >
            Discover more
          </Link>
        </div>
      </div>
    </div>
  );
}
