// src/components/HomePage.js
import React, { useState, useEffect } from 'react';
import HeroSection from './HeroSection';
import ServicesSection from './Services';
import AboutSection from './About';
import ServicesSteps from './ServicesSteps';
import ProductsSection from './Products';
import TestimonialsSection from './../Components/Header/TestimonialsVideos'
import NewsletterSection from './Newsletter';
import Footer from './Footer';
import DiseaseFormModal from './Form/form'; // Adjust the path as needed


const HomePage = () => {
    const [ShowComponents, setShowComponents] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(true);

    useEffect(() => {
        // Check if the user has previously submitted the form
        const hasSubmitted = localStorage.getItem('hasSubmittedForm');
        if (!hasSubmitted) {
            setModalIsOpen(true); // Open modal if not submitted
        }
    }, []);

    const closeModal = () => {
        setModalIsOpen(false);
        localStorage.setItem('hasSubmittedForm', 'true'); // Set flag to prevent re-opening
    };

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.offsetHeight;
            const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
            const maxScroll = documentHeight - windowHeight;
            const percentage = (scrollPosition / maxScroll) * 100;

            if (percentage > 50) {
                setShowComponents(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);
    return (
        <> 
        {/* Disease Form Modal */}
            <DiseaseFormModal isOpen={modalIsOpen} onClose={closeModal} />
            <HeroSection />
            <ServicesSection />
            {/* {ShowComponents && (
                <> */}
            <AboutSection />
            <ServicesSteps />
            <ProductsSection />
            <TestimonialsSection />
          
            {/* </>
            )} */}
        </>
    );
};

export default HomePage;
