import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import Cookies from 'js-cookie';

const RegisterPage = () => {
    const navigate = useNavigate();  // Initialize navigate function

    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [Phone, setPhone] = useState('');
    const [Password, setPassword] = useState('');
    const [Address1, setAddress1] = useState('');
    const [Address2, setAddress2] = useState('');
    const [City, setCity] = useState('');
    const [State, setState] = useState('');
    const [ZipCode, setZipCode] = useState('');
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formErrors = {};
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phoneRegex = /^[0-9]{10}$/;
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const zipCodeRegex = /^[0-9]{6}$/;

        if (!FirstName) formErrors.FirstName = 'First name is required';
        if (!LastName) formErrors.LastName = 'Last name is required';
        if (!Email || !emailRegex.test(Email)) formErrors.Email = 'Invalid email format';
        if (!Phone || !phoneRegex.test(Phone)) formErrors.Phone = 'Phone number must be 10 digits';
        if (!Password || !passwordRegex.test(Password)) formErrors.Password = 'Password must be at least 8 characters long, with 1 number and 1 special character';
        if (!ZipCode || !zipCodeRegex.test(ZipCode)) formErrors.ZipCode = 'ZIP code must be 6 digits';
        if (!Address1) formErrors.Address1 = 'Address Line 1 is required';
        if (!City) formErrors.City = 'City is required';
        if (!State) formErrors.State = 'State is required';

        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        setErrors(formErrors);

        if (Object.keys(formErrors).length > 0) return; // Don't submit if there are errors

        const formData = new FormData();
        formData.append('FirstName', FirstName);
        formData.append('LastName', LastName);
        formData.append('Email', Email);
        formData.append('Phone', Phone);
        formData.append('Password', Password);
        formData.append('Address1', Address1);
        formData.append('Address2', Address2);
        formData.append('City', City);
        formData.append('State', State);
        formData.append('ZipCode', ZipCode);

        try {
            const response = await fetch(`${config.API_BASE_URL}/adduser`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
           
           // Refresh the page


            // After successful account creation, clear form fields
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setPassword('');
            setAddress1('');
            setAddress2('');
            setCity('');
            setState('');
            setZipCode('');

           

            // Check if there is any cart data in sessionStorage
            const cartData = sessionStorage.getItem('cart');
            if (cartData) {
                // If cart data exists, send it to the backend
                const useremail = Email; 
                const usermobile = Phone // Use the newly created user's email
                sendCartData(useremail,usermobile, JSON.parse(cartData));  // Send cart data and email to backend
            } else {
                // If no cart data exists, navigate to login or homepage
                navigate('/login');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error creating user: ' + error);
        }
    };

    // Function to send cart data to the server
    const sendCartData = async (useremail,usermobile,cartData) => {
        try {
            const response = await fetch(`${config.API_BASE_URL}/save-cart-data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    useremail,
                    usermobile, // Send user email with cart data
                    cartData,
                }),
            });

            if (response.ok) {
                alert('User created successfully');
                Cookies.set('username', Email);
                Cookies.set('mobile', Phone);
                // After successful data submission, navigate to the checkout page
                navigate('/checkout');
            } else {
                console.error('Failed to save cart data.');
            }
        } catch (error) {
            console.error('Error sending cart data:', error);
        }
    };

    const handlePhoneChange = (e) => {
        // Allow only numeric input and restrict it to 10 digits
        const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
        setPhone(value);
    };

    const handleZipCodeChange = async (e) => {
        // Allow only numeric input and restrict it to 6 digits
        const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
        setZipCode(value);

        if (value.length === 6) 
        {
            try {
                const response = await fetch(`https://api.postalpincode.in/pincode/${value}`);
                const data = await response.json();
    
                if (data[0].Status === 'Success') {
                    const city = data[0].PostOffice[0].Division;
                    const state = data[0].PostOffice[0].State;
    
                    setCity(city);
                    setState(state);
                } else {
                    setCity('');
                    setState('');
                    alert('No location data found for this ZIP code. Please enter your city and state manually.');
                }
                
            } 
            catch (error) 
            {
                console.error('Error fetching location data:', error);
            }
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <form className="bg-white p-10 rounded-lg shadow-lg max-w-lg w-full" onSubmit={handleSubmit}>
                <h2 className="text-2xl font-bold mb-5 text-gray-800">Registration</h2>
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="first-name">First Name</label>
                        <input type="text" id="first-name" className="p-2 border rounded-lg" placeholder="John" value={FirstName}
                            onChange={(e) => setFirstName(e.target.value)} />
                        {errors.FirstName && <span className="text-red-500 text-sm">{errors.FirstName}</span>}
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="last-name">Last Name</label>
                        <input type="text" id="last-name" className="p-2 border rounded-lg" placeholder="Doe"
                            value={LastName} onChange={(e) => setLastName(e.target.value)} />
                        {errors.LastName && <span className="text-red-500 text-sm">{errors.LastName}</span>}
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="email">E-mail</label>
                        <input type="email" id="email" className="p-2 border rounded-lg" placeholder="example@email.com" value={Email}
                            onChange={(e) => setEmail(e.target.value)} />
                        {errors.Email && <span className="text-red-500 text-sm">{errors.Email}</span>}
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="mobile">Mobile No</label>
                        <input type="text" id="mobile" className="p-2 border rounded-lg" placeholder="1234567890"
                            value={Phone} onChange={handlePhoneChange} maxLength="10" />
                        {errors.Phone && <span className="text-red-500 text-sm">{errors.Phone}</span>}
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="password">Password</label>
                        <input type="password" id="password" className="p-2 border rounded-lg" placeholder="Enter your password"
                            value={Password} onChange={(e) => setPassword(e.target.value)} />
                        {errors.Password && <span className="text-red-500 text-sm">{errors.Password}</span>}
                        <small className="text-gray-500 mt-2">Password must be at least 8 characters long, contain at least 1 number and 1 special character.</small>
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="address1">Address Line 1</label>
                        <input type="text" id="address1" className="p-2 border rounded-lg" placeholder="123 Street"
                            value={Address1} onChange={(e) => setAddress1(e.target.value)} />
                        {errors.Address1 && <span className="text-red-500 text-sm">{errors.Address1}</span>}
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="address2">Address Line 2</label>
                        <input type="text" id="address2" className="p-2 border rounded-lg" placeholder="Apt 4B"
                            value={Address2} onChange={(e) => setAddress2(e.target.value)} />
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="zip">ZIP Code</label>
                        <input type="text" id="zip" className="p-2 border rounded-lg" placeholder="123456"
                            value={ZipCode} onChange={handleZipCodeChange} maxLength="6" autoComplete="off"  />
                        {errors.ZipCode && <span className="text-red-500 text-sm">{errors.ZipCode}</span>}
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="city">City</label>
                        <input type="text" id="city" className="p-2 border rounded-lg" placeholder="New York"
                            value={City} onChange={(e) => setCity(e.target.value)} />
                        {errors.City && <span className="text-red-500 text-sm">{errors.City}</span>}
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="state">State</label>
                        <input type="text" id="state" className="p-2 border rounded-lg" placeholder="New York"
                            value={State} onChange={(e) => setState(e.target.value)} />
                        {errors.State && <span className="text-red-500 text-sm">{errors.State}</span>}
                    </div>
                   
                </div>
                <button className="mt-5 w-full bg-red-500 text-white p-2 rounded-lg">Create Account</button>
            </form>
        </div>
    );
};

export default RegisterPage;
