import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate, Link } from "react-router-dom";
import config from "../config";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import axios from "axios";
import { BiRupee } from "react-icons/bi"; // For Razorpay Icon
import TruckButton from "./TruckAnimation/TruckButton.js";
import SuccessModal from "./SuccessModal.js";

const Cart = () => {
  const [cartProduct, setCartProduct] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [deliveryCharge] = useState(0); // Delivery charge is static at ₹50
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0); // Discount amount from coupon code
  const navigate = useNavigate(); // Import login function

  useEffect(() => {
  
    const useremail = Cookies.get("username");
    const usermobile = Cookies.get("mobile");
    const fetchCartProducts = async () => {

      try {
        const response = await fetch(
          `${config.API_BASE_URL}/cart/${useremail}/${usermobile}`
        );
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setCartProduct(data);
      } catch (error) {
        console.error("Error fetching cart products:", error);
      }
    };

    fetchCartProducts();
   
  }, []);

  const placeOrder = async () => {

    
    const useremail = Cookies.get("username");
    const usermobile = Cookies.get("mobile");
    const amount = calculateSubtotal();
    const payableAmount = calculateTotal();

    console.log(useremail+" "+usermobile+" "+amount+" "+discount+" "+payableAmount)

    try {
      const response = await fetch(`${config.API_BASE_URL}/place-order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          useremail,
          usermobile,
          amount,
          discount,
          payableamount: payableAmount,
        }),
      });

      if (!response.ok)
       throw new Error(`Failed to place order. Status: ${response.status}`);

      // Clear session storage cart and update UI
      sessionStorage.removeItem("cart");
      setCartProduct([]); // Clear the cart state

      setModalMessage(
        "🎉 Your order has been successfully placed! <br/> 💊 Our health consultant will call you shortly to confirm your order details. <br/> 🚚 Thank you for choosing Nirogi Dhara!"
      );
      setShowModal(true);
      startModalTimer();
    } catch (error) {
      console.error("Error placing order:", error);
     // alert("There was an error placing the order.");
    }
  };

  const startModalTimer = () => {
    setTimeout(() => {
      setShowModal(false);
      navigate("/shop");
    }, 50000); // 50 seconds
  };

  const handlePayment = async () => {
    const useremail = Cookies.get("username");
    const usermobile = Cookies.get("mobile");
    const username = Cookies.get("nameuser");
    const amount = calculateSubtotal();
    const payableAmount = calculateTotal();

    try {
      const orderData = await axios.post(
        `${config.API_BASE_URL}/api/payment/orders`,
        {
          amount: parseFloat(payableAmount * 100), // Amount in smallest currency unit
        }
      );

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: orderData.data.amount,
        currency: orderData.data.currency,
        name: "Nirogidhara Private Limited",
        description: "Nirogi Dhara Order Booking",
        order_id: orderData.data.id,
        handler: async (response) => {
          try {
            const orderResponse = await fetch(
              `${config.API_BASE_URL}/place-order-online`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  useremail,
                  usermobile,
                  amount,
                  discount, // Ensure 'discount' is defined
                  payableamount: payableAmount,
                  capture1: response.razorpay_order_id,
                  capture2: response.razorpay_payment_id,
                  capture3: response.razorpay_signature,
                }),
              }
            );

            if (!orderResponse.ok) {
              console.error(
                `Failed to place order. Status: ${orderResponse.status}`
              );
              setPaymentStatus(false); // Payment failed
              return; // Stop further execution
            }

            // Payment successful
            setPaymentStatus(true);
          } catch (error) {
            console.error("Error placing order:", error);
            setPaymentStatus(false); // Payment failed
          }
        },
        prefill: {
          name: username,
          email: useremail,
          contact: usermobile,
        },
        notes: {
          address: "INDIA",
        },
        theme: {
          color: "#22C55E",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error("Error creating order:", error);
      setPaymentStatus(false); // Payment initialization failed
    }
  };

  const updateQuantity = async (cartid, newQty) => {
    const useremail = Cookies.get("username");
    const usermobile = Cookies.get("mobile");

    if (newQty < 1) return;

    try {
      const response = await fetch(`${config.API_BASE_URL}/update-cart`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cartid,
          useremail,
          usermobile,
          qty: newQty,
        }),
      });

      if (!response.ok)
        throw new Error(
          `Failed to update quantity. Status: ${response.status}`
        );

      setCartProduct(
        cartProduct.map((product) =>
          product.id === cartid ? { ...product, qty: newQty } : product
        )
      );
    } catch (error) {
      console.error("Error updating cart quantity:", error);
    }
  };

  const removeItem = async (cartid) => {
    const useremail = Cookies.get("username");
    const usermobile = Cookies.get("mobile");

    try {
      const response = await fetch(`${config.API_BASE_URL}/delete-from-cart`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cartid,
          useremail,
          usermobile,
        }),
      });

      if (!response.ok)
        throw new Error(`Failed to remove item. Status: ${response.status}`);

      setCartProduct(cartProduct.filter((product) => product.id !== cartid));
    } catch (error) {
      console.error("Error removing cart item:", error);
    }
  };

  const calculateSubtotal = () => {
    return cartProduct.reduce((total, product) => {
      const price = parseFloat(product.price) || 0;
      const quantity = product.qty || 1;
      return total + price * quantity;
    }, 0);
  };

  const calculateTotal = () => {
    
    const subtotal = calculateSubtotal();  // Subtotal from the cart items
  const discountAmount = discount;  // Discount from the state
  const total = subtotal - discountAmount + deliveryCharge; // Add delivery charge
  return total > 0 ? total : 0;
  };

  const applyCoupon = async (event) => {
    event.preventDefault();
    const useremail = Cookies.get("username");
    const usermobile = Cookies.get("mobile");

    try {
      const response = await fetch(`${config.API_BASE_URL}/check-coupon`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          useremail,
          usermobile,
          couponCode,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        // Alert based on specific error messages
        if (errorData.error === "Coupon is expired or inactive") {
          alert(
            "This coupon is either expired or inactive. Please check and try again."
          );
        } else {
          alert("Failed to apply coupon. Please try again.");
        }
        return;
      }

      const data = await response.json();
      const discountPercentage = data.discount; // Assuming this is a percentage
      const subtotal = calculateSubtotal();
      const discountAmount = (subtotal * discountPercentage) / 100;

      setDiscount(discountAmount); // Set the calculated discount
      
      alert(
        `Coupon applied! You get a discount of ₹${discountAmount.toFixed(2)}`
      );
    } catch (error) {
      console.error("Error applying coupon:", error);
      alert("There was an error applying the coupon: " + error.message);
    }
  };

  return (
    <section className="bg-white py-8 antialiased md:py-16">
      <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
        <h2 className="text-xl font-semibold text-gray-900 sm:text-2xl">
          Shopping Cart
        </h2>

        <div className="mt-6 sm:mt-8 md:gap-6 lg:flex lg:items-start xl:gap-8">
          <div className="w-full flex-none lg:max-w-2xl xl:max-w-4xl">
            <div className="space-y-6">
              {cartProduct.map((product) => (
                <div
                  key={product.id}
                  className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm  md:p-6"
                >
                  <div className="space-y-4 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
                    <a href="#" className="shrink-0 md:order-1">
                      <img
                        className="h-20 w-20"
                        src={`${config.API_BASE_URL}/images/${
                          JSON.parse(product.images)[0]
                        }`}
                        alt={product.productName}
                      />
                    </a>

                    <label
                      htmlFor={`counter-input-${product.id}`}
                      className="sr-only"
                    >
                      Choose quantity:
                    </label>
                    <div className="flex items-center justify-between md:order-3 md:justify-end">
                      <div className="flex items-center">
                        <button
                          type="button"
                          onClick={() =>
                            updateQuantity(
                              product.id,
                              parseInt(product.qty) - 1
                            )
                          }
                          className="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200"
                        >
                          <FaMinusCircle className="h-4 w-4 text-green-600" />
                        </button>
                        <input
                          type="text"
                          id={`counter-input-${product.id}`}
                          className="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium text-gray-900 focus:outline-none"
                          value={product.qty}
                          readOnly
                        />
                        <button
                          type="button"
                          onClick={() =>
                            updateQuantity(
                              product.id,
                              parseInt(product.qty) + 1
                            )
                          }
                          className="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200"
                        >
                          <FaPlusCircle className="h-4 w-4 text-green-600" />
                        </button>
                      </div>
                      <div className="text-end md:order-4 md:w-32">
                        <p className="text-base font-bold text-gray-900">
                          ₹ {product.price}
                        </p>
                      </div>
                    </div>

                    <div className="w-full min-w-0 flex-1 space-y-4 md:order-2 md:max-w-md">
                      <a
                        href="#"
                        className="text-base font-medium text-gray-900 hover:underline"
                      >
                        {product.productName}
                      </a>

                      <div className="flex items-center gap-4">
                        <button
                          type="button"
                          onClick={() => removeItem(product.id)}
                          className="inline-flex items-center text-sm font-medium text-red-600 hover:underline"
                        >
                          <FaMinusCircle className="me-1.5 h-5 w-5" />
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
            <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm  sm:p-6">
              <p className="text-xl font-semibold text-gray-900">
                Order summary
              </p>

              <div className="space-y-4">
                <div className="space-y-2">
                  <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500">
                      Subtotal
                    </dt>
                    <dd className="text-base font-medium text-gray-900">
                      ₹ {calculateSubtotal().toFixed(2)}
                    </dd>
                  </dl>
                  <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500">
                      Delivery Charge
                    </dt>
                    <dd className="text-base font-medium text-gray-900">
                      ₹ {deliveryCharge}
                    </dd>
                  </dl>
                  {discount > 0 && (
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-medium text-green-600">
                        Discount
                      </dt>
                      <dd className="text-base font-medium text-green-600">
                        - ₹ {discount.toFixed(2)}
                      </dd>
                    </dl>
                  )}
                </div>

                <dl className="flex items-center justify-between gap-4 border-t-2 border-gray-200 pt-4">
                  <dt className="text-base font-normal text-gray-500">
                    Total Payable
                  </dt>
                  <dd className="text-base font-bold text-gray-900">
                    ₹ {calculateTotal().toFixed(2)}
                  </dd>
                </dl>
              </div>
            </div>

            <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm  sm:p-6">
              <form onSubmit={applyCoupon}>
                <p className="text-xl font-semibold text-gray-900">
                  Apply Coupon
                </p>
                <div className="mt-2 flex items-center gap-2">
                  <label htmlFor="coupon" className="sr-only">
                    Apply coupon
                  </label>
                  <input
                    id="coupon"
                    type="text"
                    placeholder="Coupon Code"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    className="block w-full rounded-md border-gray-200 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm px-2 py-2"
                  />
                  <button
                    type="submit"
                    className="rounded-md bg-green-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    Apply
                  </button>
                </div>
              </form>
            </div>
            <div className="flex flex-col justify-center items-center gap-5 w-full">
              <TruckButton
                onClick={placeOrder}
                defaultText="Checkout As Cash On Delivery"
              />
              {/* <TruckButton onClick={handlePayment}  /> */}
              <button
                onClick={handlePayment}
                className="w-full rounded-md bg-green-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Checkout With Pay Via Razorpay
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="relative bg-white rounded-lg p-6 shadow-lg w-96">
            {/* Close Button */}
            <button
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
              onClick={() => {
                setShowModal(false); // Close modal
                window.location.href = "/shop"; // Reload and redirect
              }}
            >
              &times;
            </button>
            <h2 className="text-xl font-semibold text-green-700 mb-4">
              Order Confirmation
            </h2>
            <p
              className="text-gray-600"
              dangerouslySetInnerHTML={{ __html: modalMessage }}
            ></p>
            <div className="mt-6">
              <button
                onClick={() => {
                  window.location.href = "/shop"; // Reload and redirect
                }}
                className="w-full rounded-md bg-green-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Continue Shopping
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Cart;
