import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaRegUser } from "react-icons/fa";
import Cookies from 'js-cookie';
import config from '../../config';
import Cart from "../Cart.js";
import logo from '../../logo515.png'; // Adjust the path as necessary
import '../CSS/Header.css';

const Header = () => {

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [ApkaNaam, setApkaNaam] = useState(null); // Start as null to differentiate from empty object
  const [loading, setLoading] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [cartOpen, setCartOpen] = useState(false); // State to manage Cart component visibility

  const handleClick = () => {
    const username = Cookies.get('username');
    const mobile = Cookies.get('mobile');

    if (!username || !mobile) {
      navigate('/login');
    } else {
      // You can add other logic here if needed
    }
  };

  useEffect(() => {
    const username = Cookies.get('username');
    const mobile = Cookies.get('mobile');
    console.log("Username and Mobile from cookies:", username, mobile);

    if (!username || !mobile) {
      // If cookies are empty, skip the API call
      console.log("Cookies are empty. Showing user icon.");
      return;
    }

    const fetchUsername = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${config.API_BASE_URL}/finduser/${username}/${mobile}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setApkaNaam(data); // Store user data


        console.log(data.Firstname)
        Cookies.set('nameuser', data.Firstname);

        console.log("User data fetched:", data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchUsername();
  }, []);

  useEffect(() => {
    const useremail = Cookies.get('username');
    const usermobile = Cookies.get('mobile');
    console.log("Username and Mobile from cookies:", useremail, usermobile);
    const updateCart = async () => {
      
      try {
        console.log("HELLO N")
        const storedProducts = JSON.parse(sessionStorage.getItem('cart')) || [];
        console.log("Cart data from sessionStorage:", storedProducts);
      
        // Update cart state with data from sessionStorage
         // Set cart products
        setCartCount(storedProducts.length || 0);


      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    }

    updateCart();
  }, [cartCount]);

  return (
    <header className="flex justify-between p-2 bg-green-700 shadow-md mobile-navbar">
      <div className="flex justify-between">
        <img src={logo} alt="Logo" className="h-14 w-auto object-contain" />

        <button onClick={() => setIsOpen(!isOpen)} className="md:hidden">
          {/* Menu Icon */}
          <svg
            className="w-6 h-6 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>

      {/* Off-canvas menu */}
      <div className={`fixed z-50 top-0 right-0 h-full w-64 bg-green-700 text-white transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out md:hidden mobile-offcanvas`}>
        <button onClick={() => setIsOpen(false)} className="text-right p-4 text-2xl">✖</button>
        <nav className="flex flex-col items-start p-6 space-y-4 bg-green-700">
          <Link to="/#" onClick={() => setIsOpen(false)} className="hover:text-green-500">Home</Link>
          <Link to="/Aboutus" onClick={() => setIsOpen(false)} className="hover:text-green-500">About Us</Link>
          <Link to="/Shop" onClick={() => setIsOpen(false)} className="hover:text-green-500">Our Products</Link>
          <Link to="/TestimonialsVideos" onClick={() => setIsOpen(false)} className="hover:text-green-500">Testimonials</Link>
          <Link to="/blog" onClick={() => setIsOpen(false)} className="hover:text-green-500">Our Blogs</Link>
          <Link to="/contact" onClick={() => setIsOpen(false)} className="hover:text-green-500">Contact</Link>
        </nav>
      </div>

       <nav className="hidden md:flex space-x-6 text-white">
        <Link to="/#" className="hover:text-green-500 transition">Home</Link>
        <Link to="/Aboutus" className="hover:text-green-500 transition">About Us</Link>
        <Link to="/Shop" className="hover:text-green-500 transition">Our Products</Link>
        <Link to="/TestimonialsVideos" className="hover:text-green-500 transition">Testimonials</Link>
        <Link to="/blog" className="hover:text-green-500 transition">Our Blogs</Link>
        <Link to="/contact" className="hover:text-green-500 transition">Contact</Link>
      </nav>

      <div className="flex items-center space-x-4 justify-between">


        <div className="relative text-white scale-110 hover:cursor-pointer" onClick={handleClick}>
          {/* Show user name if ApkaNaam.Firstname exists, otherwise show the icon */}
          {loading ? (
            <span>Loading...</span>
          ) : ApkaNaam && ApkaNaam.Firstname ? (
            <>{ApkaNaam.Firstname}</>
          ) : (
            <FaRegUser />
          )}
        </div>

        {/* Cart Icon with Badge */}
        <button className="relative" onClick={() => setCartOpen(!cartOpen)}>
          <span className="absolute top-0 right-0 bg-red-600 text-white text-xs rounded-full px-1.5">{cartCount}</span>
          {/* Cart SVG Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 h-8 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 3h2l.4 2M7 13h10l1.1-5.5H6.4L7 13zm5 8a2 2 0 100-4 2 2 0 000 4zm7 0a2 2 0 100-4 2 2 0 000 4z"
            />
          </svg>
        </button>

        {/* Conditionally render the Cart component */}
        {cartOpen && <Cart cartCount={cartCount} />}
      </div>
    </header>
  );
};

export default Header;
