import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import config from "../config";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Custom Arrow Components
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute top-1/2 right-4 transform -translate-y-1/2 z-10 cursor-pointer bg-green-600 text-white rounded-full p-2 shadow-lg hover:bg-green-700"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5l7 7-7 7"
        />
      </svg>
    </div>
  );
};

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute top-1/2 left-4 transform -translate-y-1/2 z-10 cursor-pointer bg-green-600 text-white rounded-full p-2 shadow-lg hover:bg-green-700"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </div>
  );
};

const ProductsSection = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  const productview = (productId) => {
    navigate(`/singleproduct/${productId}`);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/fetchproducts`);
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const truncateDescription = (description, wordLimit) => {
    return (
      description.split(" ").slice(0, wordLimit).join(" ") +
      (description.split(" ").length > wordLimit ? "..." : "")
    );
  };

  // Slider settings for responsiveness
  const sliderSettings = {
    dots: false, // Dots removed
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Default for desktop
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // Mobile view
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="bg-white">
        <div className="container px-4 relative mx-auto">
        <h1 className="text-3xl text-center font-bold text-green-800">
          Our Recent Top Selling Products
        </h1>
        <Slider {...sliderSettings} className="mt-4 mb-4">
          {products.map((product, index) => (
            <div className="p-1">
              <div
                onClick={() => productview(product.id)}
                key={index}
                className="p-4 text-center bg-white rounded-lg shadow hover:border border-gray-400 mx-2"
                style={{ margin: "0px 10px" }} // Add spacing between slides
              >
                {product.images && product.images.length > 0 ? (
                  <img
                    src={`${config.API_BASE_URL}/images/${
                      JSON.parse(product.images)[0]
                    }`}
                    alt="Product Image 0"
                    className="w-full h-50 object-cover rounded-lg mb-2"
                  />
                ) : (
                  <p>No image available</p>
                )}
                <h3 className="text-lg font-semibold text-gray-800">
                  {product.productName}
                </h3>
                <p className="mt-3 text-gray-600">
                  {truncateDescription(product.description, 15)}
                </p>
                <div className="flex items-center mb-2">
                  <p className="text-red-600 text-3xl line-through mr-4">
                    ₹ {product.overprice}/-
                  </p>
                  <p className="text-green-600 text-3xl ml-4">
                    ₹ {product.price}/-
                  </p>
                </div>
                <button
                  onClick={() => productview(product.id)}
                  className="mt-4 mx-4 bg-green-600 text-white py-2 px-4 rounded-full hover:bg-green-700 transition duration-300"
                >
                  View Product
                </button>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default ProductsSection;
