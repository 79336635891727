import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import config from '../config';
import { motion } from 'framer-motion';
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';  // Updated to useNavigate

export default function Cart() {
    const [open, setOpen] = useState(true);
    const [cartProduct, setCartProduct] = useState([]);
    const navigate = useNavigate();
    
    const handleCheckout = () => {
        const useremail = Cookies.get('username'); // Check if the user is logged in
        const usermobile = Cookies.get('mobile');
        if (!useremail) {
            // If the user is not logged in, prompt them to register
            if (window.confirm('You need to register first. Do you want to register now?')) {
                setOpen(false);
                navigate('/create-account');  // Use navigate to redirect to the registration page
            }
        } else {
            // If the user is logged in, send cart data to the database
            const cartData = JSON.parse(sessionStorage.getItem('cart')) || [];

            const sendCartData = async () => {
                try {
                    const response = await fetch(`  /save-cart-data`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            useremail,
                            usermobile, // Send user email with cart data
                            cartData,
                        }),
                    });

                    if (response.ok) {
                        setOpen(false)
                        // After successful data submission, navigate to the checkout page
                        navigate('/checkout');  // Use navigate for redirecting to checkout
                    } else {
                        console.error('Failed to save cart data.');
                    }
                } catch (error) {
                    console.error('Error sending cart data:', error);
                }
            };

            sendCartData(); // Send cart data to the backend and navigate to checkout
        }
    };
    useEffect(() => {
        const useremail = Cookies.get('username');
        const usermobile = Cookies.get('mobile');
        
        const fetchCartProducts = async () => {
            if (useremail && usermobile) {
                try {
                    const storedProducts = JSON.parse(sessionStorage.getItem('cart')) || [];
                    setCartProduct(storedProducts);
                } catch (error) {
                    console.error('Error fetching cart products from API:', error);
                }
            } else {
                const storedProducts = JSON.parse(sessionStorage.getItem('cart')) || [];
                setCartProduct(storedProducts);
            }
        };

        fetchCartProducts();
    }, []);

    const updateQuantity = async (cartid, newQty) => {
        if (newQty < 1 || isNaN(newQty)) return;

        const useremail = Cookies.get('username');
        const usermobile = Cookies.get('mobile');


        const updatedCart = cartProduct.map(product =>
            product.id === cartid ? { ...product, qty: newQty } : product
        );
        setCartProduct(updatedCart);
        sessionStorage.setItem('cart', JSON.stringify(updatedCart));
        try {
            if (useremail && usermobile) 
                {
                


               
            } else {
                const updatedCart = cartProduct.map(product =>
                    product.id === cartid ? { ...product, qty: newQty } : product
                );
                setCartProduct(updatedCart);
                sessionStorage.setItem('cart', JSON.stringify(updatedCart));
                window.location.reload(); 
            }
        } catch (error) {
            console.error('Error updating cart quantity:', error);
        }
    };

   const removeItem = (cartid) => {
    try {
        // Retrieve the current cart data from sessionStorage
        const storedCart = JSON.parse(sessionStorage.getItem('cart')) || [];

        // Filter out the product with the given cartid
        const updatedCart = storedCart.filter(product => product.id !== cartid);

        // If no products remain in the cart, update sessionStorage to empty array
        if (updatedCart.length === 0) {
            sessionStorage.removeItem('cart');
        } else {
            // Update sessionStorage with the updated cart
            sessionStorage.setItem('cart', JSON.stringify(updatedCart));
        }

        // Update the state with the updated cart
        setCartProduct(updatedCart);

        console.log("Item removed successfully.");
        window.location.reload(); 
    } catch (error) {
        console.error('Error removing cart item:', error);
    }
};

    

    const calculateSubtotal = () => {
        return cartProduct.reduce((total, product) => {
            const price = parseFloat(product.price) || 0;
            const quantity = product.qty || 1;
            return total + price * quantity;
        }, 0);
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
            <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <DialogPanel className="pointer-events-auto w-screen max-w-md">
                            <motion.div
                                className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                                initial={{ x: '100vw' }}
                                animate={{ x: 0 }}
                                transition={{ type: 'tween', stiffness: 300 }}
                            >
                                <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                                    <div className="flex items-start justify-between">
                                        <DialogTitle className="text-lg font-medium text-gray-900">Shopping cart</DialogTitle>
                                        <div className="ml-3 flex h-7 items-center">
                                            <button
                                                type="button"
                                                onClick={() => setOpen(false)}
                                                className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                                            >
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="mt-8">
                                        <div className="flow-root">
                                            <ul role="list" className="-my-6 divide-y divide-gray-200">
                                                {cartProduct.map((product, index) => {
                                                    let images = [];
                                                    try {
                                                        images = product.images ? JSON.parse(product.images) : [];
                                                    } catch (error) {
                                                        console.error('Error parsing product images:', error);
                                                        images = [];
                                                    }

                                                    return (
                                                        <motion.li
                                                            key={product.id}
                                                            className="flex py-6"
                                                            initial={{ opacity: 0, x: 30 }}
                                                            animate={{ opacity: 1, x: 0 }}
                                                            transition={{ delay: index * 0.1, duration: 0.9 }}
                                                        >
                                                            <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                                                <img
                                                                    alt={product.productName}
                                                                    src={product.image ? `${config.API_BASE_URL}/images/${product.image}` : ''}
                                                                    className="h-full w-full object-cover object-center"
                                                                />
                                                            </div>

                                                            <div className="ml-4 flex flex-1 flex-col">
                                                                <div>
                                                                    <div className="flex justify-between text-base font-medium text-gray-900">
                                                                        <h3>{product.productName}</h3>
                                                                        <p className="ml-4">₹ {product.price}/-</p>
                                                                    </div>
                                                                    <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                                                                </div>

                                                                <div className="flex flex-1 items-end justify-between text-sm">
                                                                    <div className="flex items-center">
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => updateQuantity(product.id, product.qty - 1)}
                                                                            className="px-2 text-green-500 hover:text-green-700"
                                                                        >
                                                                            <FaMinusCircle />
                                                                        </button>
                                                                        <input
                                                                            type="number"
                                                                            value={product.qty}
                                                                            onChange={(e) => updateQuantity(product.id, Math.max(1, parseInt(e.target.value)))}
                                                                            className="mx-2 w-12 border border-green-500 text-center"
                                                                            min="1"
                                                                        />
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => updateQuantity(product.id, product.qty + 1)}
                                                                            className="px-2 text-green-500 hover:text-green-700"
                                                                        >
                                                                            <FaPlusCircle />
                                                                        </button>
                                                                    </div>
                                                                    <div className="flex">
                                                                        <motion.button
                                                                            type="button"
                                                                            onClick={() => removeItem(product.id)}
                                                                            className="font-medium text-red-600 hover:text-red-500"
                                                                            whileHover={{ scale: 1.1, rotate: -5 }}
                                                                            whileTap={{ scale: 0.9 }}
                                                                            transition={{ type: 'spring', stiffness: 300 }}
                                                                        >
                                                                            Remove
                                                                        </motion.button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </motion.li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                                    <div className="flex justify-between text-base font-medium text-gray-900">
                                        <span>Subtotal</span>
                                        <span>₹ {calculateSubtotal()}/-</span>
                                    </div>
                                    <div className="mt-6">
                                        {/* <Link to="/checkout"> */}
                                            <button
                                                type="button"
                                                className="w-full rounded-md border border-transparent bg-green-600 py-3 px-4 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                                onClick={handleCheckout}
                                            >
                                                Checkout
                                            </button>
                                        {/* </Link> */}
                                    </div>
                                    <div className="mt-6 flex justify-center text-sm text-gray-500">
                                        <button
                                            type="button"
                                            onClick={() => setOpen(false)}
                                            className="font-medium text-green-600 hover:text-green-500"
                                        >
                                            Continue shopping
                                        </button>
                                    </div>
                                </div>
                            </motion.div>
                        </DialogPanel>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
